.about-grid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-grid .borderRounded {
    width: 7px;
    background: #47b2e4;
    height: 32px;
    margin: 0px 20px;
}

.descriptionText p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    opacity: 80%;
}

.pricing .box:hover {
    border-top-color: #47b2e4;
}