.loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999999;
    background-color: rgb(64 81 137 / 31%);
    left: 0;
}